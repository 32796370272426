<template>
  <v-card>
    <v-row
      justify="space-between"
      class="pa-4 d-flex flex-nowrap"
    >
      <!-- Bar left -->
      <v-col cols="3" style="border-right: 1px solid grey">
        <v-row>
          <v-col
            cols="12"
            class="py-0 my-0"
          >
            <v-text-field
              v-model="stakeholderSearchString"
              outlined
              label="Search for Stakeholder"
              @keyup.enter.native="updatedSearchFormats"
              @keyup.native="updatedSearchFormats"
              append-icon="mdi-magnify"
              @click:append="updatedSearchFormats"
              @click:clear="clearSearch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-treeview
          v-if="selectedStakeholder"
          :active.sync="selectedStakeholder"
          :items="stakeholders"
          item-text="friendlyName"
          color="primary"
          activatable
          return-object
        >
          <template v-slot:prepend="{ item }">
            <v-icon> mdi-office-building </v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-icon v-if="loggedInUserStakeholderId == item.id">
              mdi-crown
            </v-icon>
          </template>
        </v-treeview>
      </v-col>
      <!-- Main left -->
      <v-col cols="8">
        <v-scroll-y-transition mode="out-in">
          <span v-if="!selectedStakeholder">
            Select a stakeholder to see more details.
          </span>
          <v-card
            v-else
            :key="selectedStakeholder[0].id"
            class="mx-auto"
            flat
          >
            <v-row>
              <v-col cols="2">
              <v-avatar size="100">
              <v-img
                  :src="
                    selectedStakeholder[0].logo === null
                      ? 'contact-placeholder.jpg'
                      : selectedStakeholder[0].logo.urlThumb
                  "
                ></v-img>
              </v-avatar>
              </v-col>
              <v-col
                cols="8"
                class="py-4"
              >
                <h3 class="headline">
                  {{ selectedStakeholder[0].friendlyName }}
                </h3>
                <div class="d-flex">
                  <div class="blue--text">
                    {{ selectedStakeholder[0].primaryRole }}
                  </div>
                </div>
                <div class="d-flex mt-1" style="gap: 1em">
                  <div class="blue--text">
                    Address:
                  </div>
                  <div>
                     {{ selectedStakeholder[0].address.addressLine1 +' '+ selectedStakeholder[0].address.addressLine2+', '+selectedStakeholder[0].address.city+', '+selectedStakeholder[0].address.postcode+', ' + selectedStakeholder[0].address.countryCode  }}
                  </div>
                </div>
              </v-col>
              <v-col
                cols="2"
                class="text-right"
              >
                <!-- {{selectedStakeholder[0]}} -->
                <v-btn
                  v-if="$store.state.Permissions.StakeholderUpdate"
                  fab
                  small
                  color="primary"
                  @click="
                    editStakeholderDialog = true;
                    stakeholderToEdit = selectedStakeholder[0];
                    profilePictureURL = selectedStakeholder[0].logo === null? null: selectedStakeholder[0].logo.urlThumb;
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <div v-if="selectedStakeholder[0].contactsUsers.length > 0">
              <v-row>
                <v-col
                  cols="6"
                  class="mt-4 pa-4 text-left"
                >
                  <v-icon>mdi-account-multiple</v-icon>
                  <span class="text-h4"> Contacts</span>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    fab
                    small
                    color="primary"
                    @click="
                      addContactDialog = true;
                      selectedStakeholderId = selectedStakeholder[0].id;
                      profilePictureURL = null;
                    "
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="selectedStakeholder[0].contactsUsers"
                  >
                    <template v-slot:item.image="{ item }">
                      <div class="ma-2">
                        <v-avatar size="85">
                          <img
                            :src="
                              item.contactCard.profilePicture
                                ? item.contactCard.profilePicture.urlThumb
                                : 'contact-placeholder.jpg'
                            "
                          >
                        </v-avatar>
                      </div>
                    </template>
                    <template v-slot:item.user="{ item }">
                      <div
                        v-if="item.user"
                        class="ma-2"
                      >
                        true
                      </div>
                      <div
                        v-else
                        class="ma-2"
                      >
                        false
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        icon
                        color="primary"
                        @click="
                          editContactCardDialog = true;
                          selectedUserForEdit = item;
                          selectedUserForEdit.contactCard.profilePicture === null? profilePictureURL = null: profilePictureURL = selectedUserForEdit.contactCard.profilePicture.urlThumb;
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <span>There doesn't seem to be any contacts here yet.</span>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    @click="
                      addContactDialog = true;
                      selectedStakeholderId = selectedStakeholder ? selectedStakeholder[0].id : 0;
                    "
                  >
                    <v-icon>mdi-plus</v-icon>Contact
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
      <v-dialog
        v-if="stakeholderToEdit"
        v-model="editStakeholderDialog"
        width="600"
      >
        <v-card>
          <v-card-title
            class="white--text primary"
          >
            Edit Stakeholder
          </v-card-title>
          <v-row class="pa-4 text-center">
            <v-col cols="12">
              <v-avatar
                size="180"
                tile
              >
                <img
                  style="cursor:pointer;"
                  :src="
                    profilePictureURL === null
                      ? 'contact-placeholder.jpg'
                      : profilePictureURL
                  "
                  @click="$refs.stakeholderLogoUpload.click()"
                >
              </v-avatar>
              <input
                ref="stakeholderLogoUpload"
                style="display:none;"
                type="file"
                accept="image/*"
                @change="addNewStakeholderImgToCropper"
              >
            </v-col>
            <!-- <v-col cols="9"> -->
            <!-- <v-file-input
                outlined
                accept="image/*"
                v-model="uploadedStakeholderLogo"
                label="Stakeholder Logo"
                @change="addNewStakeholderImgToCropper"
                :clearable="false"
              /> -->

            <!-- </v-col> -->
            <v-col cols="6">
              <v-text-field
                v-model="stakeholderToEdit.name"
                outlined
                label="Name*"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="stakeholderToEdit.primaryRole"
                outlined
                :items="roles"
                label="Primary Role*"
                item-text="name"
                :item-value="'name'"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="stakeholderToEdit.address.addressLine1"
                outlined
                label="Address Line 1*"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="stakeholderToEdit.address.addressLine2"
                outlined
                label="Address Line 2"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="stakeholderToEdit.address.city"
                outlined
                label="City*"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="stakeholderToEdit.address.postcode"
                outlined
                label="Postcode*"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="stakeholderToEdit.address.countryCode"
                outlined
                :items="countryCodeOptions"
                item-text="name"
                :item-value="'code'"
                label="Country*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col
              cols="6"
              class="text-left"
            >
              <v-btn
                v-if="$store.state.Permissions.StakeholderDelete"
                color="red"
                @click="promptDeleteStakeholderConfirmation = true"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="editStakeholder"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-form
        ref="contactCardForm"
        v-model="validContactCard"
      >
        <v-dialog
          v-model="addContactDialog"
          width="600"
        >
          <v-card>
            <v-card-title
              class="white--text primary"
            >
              Create Contact
            </v-card-title>
            <v-row class="pa-4">
              <v-col
                cols="12"
                class="text-center"
              >
                <v-avatar size="180">
                  <img
                    style="cursor:pointer;"
                    :src="
                      profilePictureURL === null
                        ? 'contact-placeholder.jpg'
                        : profilePictureURL
                    "
                    @click="$refs.contactPicUploadNew.click()"
                  >
                </v-avatar>
                <input
                  ref="contactPicUploadNew"
                  style="display:none;"
                  type="file"
                  accept="image/*"
                  @change="addNewImgToCropper"
                >
                <!-- <v-img
                  :src="
                    profilePictureURL === null
                      ? 'contact-placeholder.jpg'
                      : profilePictureURL
                  "
                ></v-img> -->
              </v-col>
              <!-- <v-col cols="9">
                <v-file-input
                  outlined
                  v-model="uploadedContactCardLogo"
                  label="Contact Logo"
                  @change="addNewImgToCropper"
                />

                <v-dialog v-model="cropperDialog" width="600">
                  <v-card class="pa-4">
                    <v-col cols="12">
                      <cropper ref="cropper" :src="imgUrl" height="20" />
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn color="primary" @click="cropImage">crop</v-btn>
                    </v-col>
                  </v-card>
                </v-dialog>
              </v-col> -->
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="newContactCard.email"
                  outlined
                  label="Email*"
                  type="email"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="newContactCard.firstName"
                  outlined
                  label="First Name*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="newContactCard.surname"
                  outlined
                  label="Surname*"
                  :rules="requiredRule"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="newContactCard.countryCode"
                  outlined
                  :items="countryCodeOptions"
                  item-text="name"
                  :item-value="'code'"
                  label="Country"
                />
              </v-col>
           <!--    <v-col
                cols="6"
              >
                <v-text-field
                  v-model="newContactCard.phoneNumber"
                  outlined
                  label="Phone Number"
                  type="number"
                />
              </v-col> -->

              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="newContactCard.jobTitle"
                  outlined
                  label="Job Title"
                />
              </v-col>

              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  @click="createContactCard"
                >
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-form>
      <v-dialog
        v-if="selectedUserForEdit"
        v-model="editContactCardDialog"
        width="600"
      >
        <v-card>
          <v-card-title class="white--text primary">
            Edit Contact
          </v-card-title>
          <v-row class="pa-4">
            <v-col
              cols="12"
              class="text-center"
            >
              <v-avatar size="180">
                <img
                  style="cursor:pointer;"
                  :src="
                    profilePictureURL === null
                      ? 'contact-placeholder.jpg'
                      : profilePictureURL
                  "
                  @click="$refs.contactPicUpload.click()"
                >
              </v-avatar>
              <input
                ref="contactPicUpload"
                style="display:none;"
                type="file"
                accept="image/*"
                @change="addNewImgToCropper"
              >
            </v-col>
            <!-- <v-col cols="3">
              <v-img
                :src="imgUrl === null ? 'contact-placeholder.jpg' : imgUrl"
              ></v-img>
            </v-col> -->

            <!-- <v-col cols="9">
              <v-file-input
                outlined
                v-model="uploadedContactCardLogo"
                label="Contact Logo"
                @change="addNewImgToCropper"
              />
            </v-col> -->
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="selectedUserForEdit.contactCard.email"
                outlined
                label="Email"
                type="email"
                :disabled="editContactCard"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="selectedUserForEdit.contactCard.firstName"
                outlined
                label="First Name"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="selectedUserForEdit.contactCard.surname"
                outlined
                label="Surname"
              />
            </v-col>
          <!--   <v-col
              cols="6"
            >
              <v-text-field
                v-model="selectedUserForEdit.contactCard.phoneNumber"
                outlined
                label="Phone Number"
              />
            </v-col> -->
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="selectedUserForEdit.contactCard.jobTitle"
                outlined
                label="Job Title"
              />
            </v-col>

            <v-col
              cols="6"
              class="text-right"
            >
              <v-select
                v-model="selectedUserForEdit.contactCard.countryCode"
                outlined
                :items="countryCodeOptions"
                item-text="name"
                :item-value="'code'"
                label="Country"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="editContactCard"
              >
                SAVE
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="promptCreateUserDialog"
        width="600"
      >
        <v-card class="pa-4">
          <span>Contact created successfully, would you like to create a user
            account for this person?</span>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedRole"
                outlined
                label="Select Role For New User*"
                :items="roles"
                item-text="name"
                :item-value="'id'"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="selectedRole === null"
              @click="createUserAccForNewContactCard"
            >
              Confirm
            </v-btn>
            <v-btn
              color="white"
              @click="
                promptCreateUserDialog = false;
                createdContactCardId = null;
              "
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="promptDeleteStakeholderConfirmation"
        width="600"
      >
        <v-card class="pa-4">
          <span>Are you sure you want to delete this stakeholder?</span>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="deleteStakeholder(stakeholderToEdit)"
            >
              Confirm
            </v-btn>
            <v-btn
              color="white"
              @click="promptDeleteStakeholderConfirmation = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="cropperDialog"
      width="600"
    >
      <v-card class="pa-4">
        <v-col cols="12">
          <cropper
            ref="cropper"
            class="cropper"
            :src="imgUrl"
            :stencil-component="$options.components.RectangleStencil"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            @click="cropImage"
          >
            save
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import StakeholderController from '@/services/controllers/Stakeholder'
  import UserController from '@/services/controllers/User'
  import CampaignController from '@/services/controllers/Campaign'
  import countryPhonePrefixes from '../../../public/country-phone-prefix.json'
  import { Cropper } from 'vue-advanced-cropper'

  export default {
    components: { Cropper },

    data: () => ({
      stakeholderToEdit: null,
      selectedStakeholder: null,
      editStakeholderDialog: false,
      requiredRule: [(v) => !!v || 'Field is required'],
      stakeholders: [],
      validContactCard: false,
      promptDeleteStakeholderConfirmation: false,
      stakeholderSearchString: null,
      loggedInUserStakeholderId: null,
      selectedRole: null,
      mediaUploadId: null,
      imgUrl: null,
      cropperDialog: false,
      croppedImgB64: null,
      uploadedStakeholderLogo: null,
      editContactCardDialog: false,
      promptCreateUserDialog: false,
      createdContactCardId: null,
      countryCodeOptions: countryPhonePrefixes,
      uploadedContactCardLogo: null,
      addContactDialog: false,
      selectedUserForEdit: null,
      profilePictureURL: null,
      roles: [],
      newContactCard: {
        firstName: null,
        surname: null,
        countryCode: null,
        phoneNumber: null,
        email: null,
        jobTitle: null,
      },
      headers: [
        {
          sortable: true,
          value: 'image',
        },
        {
          sortable: true,
          text: 'Forename',
          value: 'contactCard.firstName',
        },
        {
          sortable: true,
          text: 'Surname',
          value: 'contactCard.surname',
        },
        {
          sortable: true,
          text: 'Title',
          value: 'contactCard.jobTitle',
        },
     
        {
          sortable: false,
          text: 'Email',
          value: 'contactCard.email',
        },
        {
          sortable: false,
          text: 'Has User Account?',
          value: 'user',
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
        },
      ],
      searchTimeout: null,
    }),

    watch: {
      selectedStakeholder (value) {
        UserController.searchContactCardsById(value[0].id).then((res) => {
          value.contactUsers = res.data
        })
      },
    },

    created () {
      this.getRoles()
      UserController.whoAmI().then((res) => {
        // Id of stakeholder that logged in user belongs to
        if (res.data.contactCard) {
          this.loggedInUserStakeholderId = res.data.contactCard.stakeholder.id
        }
      })
      this.getAllStakeholders()
    },

    methods: {
      updatedSearchFormats() {
        if (this.stakeholderSearchString === null || this.stakeholderSearchString === undefined || this.stakeholderSearchString === '') {
          this.getAllStakeholders()
          return
        }
        if (this.stakeholderSearchString.length < 1) {
          return
        }
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
          this.searchForStakeholder()
        }, 1000)
      },
      cropImage () {
        const { canvas } = this.$refs.cropper.getResult()
        let resultFromApi
        var checkForResult = setInterval(() => {
          if (resultFromApi !== undefined) {
            clearInterval(checkForResult)
            this.mediaUploadId = resultFromApi.id
            this.profilePictureURL = resultFromApi.url
            this.imgUrl = null
            this.cropperDialog = false
          }
        }, 100)
        if (canvas) {
          const form = new FormData()
          canvas.toBlob((blob) => {
            // form.append("file", blob);
            var reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              var base64data = reader.result

              form.append('fileBase64', base64data)
              form.append('campaignId', 0)

              CampaignController.uploadB64(form)
                .then((b64res) => {
                  resultFromApi = b64res.data
                })
                .catch((err) => {
                  clearInterval(checkForResult)
                })
            }
          }, 'image/png')
        }
      },

      imageCroppedSuccess (ev) {
        this.cropperDialog = false
        this.croppedImgB64 = ev
        const formData = new FormData()
        formData.append('fileBase64', this.croppedImgB64)
        formData.append('campaignId', 0)

        CampaignController.uploadB64(formData).then((b64res) => {
          this.mediaUploadId = b64res.data.id
        })
      },

      addNewStakeholderImgToCropper (e) {
        this.uploadedStakeholderLogo = Object.values(e.target.files)[0]
        this.imgUrl = URL.createObjectURL(this.uploadedStakeholderLogo)
        this.cropperDialog = true
      },

      editStakeholder () {
        StakeholderController.editStakeholder({
          id: this.stakeholderToEdit.id,
          name: this.stakeholderToEdit.name,
          address: {
            countryCode: this.stakeholderToEdit.address.countryCode,
            addressLine1: this.stakeholderToEdit.address.addressLine1,
            addressLine2: this.stakeholderToEdit.address.addressLine2,
            city: this.stakeholderToEdit.address.city,
            postcode: this.stakeholderToEdit.address.postcode,
          },
          logoId: this.mediaUploadId === null ? 0 : this.mediaUploadId,
          primaryRole: this.stakeholderToEdit.primaryRole,
        })
          .then((res) => {
            this.$root.$emit(
              'snackbarSuccess',
              'Stakeholder edited successfully.',
            )
            this.editStakeholderDialog = false
            this.selectedUserForEdit = null
            this.mediaUploadId = null
            this.profilePictureURL = null
            this.imgUrl = null

            this.getAllStakeholders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      editContactCard () {
        UserController.editContactCard({
          id: this.selectedUserForEdit.contactCard.id,
          firstName: this.selectedUserForEdit.contactCard.firstName,
          surname: this.selectedUserForEdit.contactCard.surname,
          countryCode: this.selectedUserForEdit.contactCard.countryCode,
          phoneNumber: this.selectedUserForEdit.contactCard.phoneNumber,
          jobTitle: this.selectedUserForEdit.contactCard.jobTitle,
          email: this.selectedUserForEdit.contactCard.email,
          stakeholderId: this.selectedUserForEdit.contactCard.stakeholder.id,
          profilePictureId: this.mediaUploadId === null ? 0 : this.mediaUploadId,
        })
          .then((res) => {
            this.getAllStakeholders()
            this.uploadedContactCardLogo = null
            this.imgUrl = null
            this.mediaUploadId = null
            this.selectedUserForEdit = null
            this.editContactCardDialog = false
            this.$root.$emit('snackbarSuccess', 'Contact edited successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      createUserAccForNewContactCard () {
        UserController.createUserFromContactCard({
          contactCardId: this.createdContactCardId,
          roleId: this.selectedRole,
        })
          .then((res) => {
            this.createdContactCardId = null
            this.selectedRole = null
            this.$root.$emit(
              'snackbarSuccess',
              'User account created successfully.',
            )
            this.promptCreateUserDialog = false
            this.getAllStakeholders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      getRoles () {
        StakeholderController.getPrimaryRoles().then((res) => {
          this.roles = res.data
        })
      },

      addNewImgToCropper (e) {
        this.uploadedContactCardLogo = Object.values(e.target.files)[0]
        this.imgUrl = URL.createObjectURL(this.uploadedContactCardLogo)
        this.cropperDialog = true
      },

      createContactCard () {
        this.$refs.contactCardForm.validate()
        if (this.validContactCard === true) {
          UserController.createContactCard({
            firstName: this.newContactCard.firstName,
            surname: this.newContactCard.surname,
            countryCode: this.newContactCard.countryCode,
            phoneNumber: this.newContactCard.phoneNumber,
            jobTitle: this.newContactCard.jobTitle,
            email: this.newContactCard.email,
            stakeholderId: this.selectedStakeholderId,
            profilePictureId:
              this.mediaUploadId === null ? 0 : this.mediaUploadId,
          })
            .then((res) => {
              this.mediaUploadId = null
              this.uploadedContactCardLogo = null
              this.imgUrl = null
              this.profilePictureURL = null
              this.newContactCard = {
                firstName: null,
                surname: null,
                countryCode: null,
                phoneNumber: null,
                email: null,
                jobTitle: null,
              }
              this.addContactDialog = false
              this.createdContactCardId = res.data.id
              this.promptCreateUserDialog = true
              this.$root.$emit(
                'snackbarSuccess',
                'Contact created successfully.',
              )

              this.getAllStakeholders()
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        }
      },

      deleteStakeholder (item) {
        StakeholderController.deleteStakeholderById(item.id)
          .then((res) => {
            this.$root.$emit(
              'snackbarSuccess',
              'Stakeholder deleted successfully.',
            )
            this.promptDeleteStakeholderConfirmation = false
            this.editStakeholderDialog = false
            this.getAllStakeholders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      clearSearch () {
        this.stakeholderSearchString = null
        this.getAllStakeholders()
      },

      searchForStakeholder () {
        StakeholderController.searchStakeholders(this.stakeholderSearchString)
          .then((res) => {
            this.stakeholders = res.data
          })
          .catch((err) => {
          })
      },

      getAllStakeholders () {
        StakeholderController.allStakeholders()
          .then((res) => {
            // this.stakeholders = [];
            this.stakeholders = res.data

            // If user cannot read own stakeholder, remove from the list else display as first item.
            if (this.$store.state.Permissions.StakeholderReadSelf === false) {
              for (var i = 0; i < this.stakeholders.length; i++) {
                if (this.stakeholders[i].id === this.loggedInUserStakeholderId) {
                  this.stakeholders.splice(i, 1)
                }
              }
            } else {
              const search = (what) =>
                this.stakeholders.find((element) => element.id === what)
              this.selectedStakeholder = [search(this.loggedInUserStakeholderId)]
            }
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message);
          })
      },
    },
  }
</script>
<style>
.cropper {
  height: 600px;
}
</style>
